import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.css';
import Cookies from 'universal-cookie';
import video from '../../assets/videos/video.mp4';
import { Link } from 'react-router-dom';

function Body() {
    const cookie = new Cookies();

    return (
        <Box className='bodyWrapper'>
            <h1>Discover the Future <br/>of Cryptocurrency</h1>
            <Link to='/signup'>
                <Button className='bodyButton' variant='contained'> 
                    Start Today!
                </Button>
            </Link>
        </Box>
    );
}

export default Body;
