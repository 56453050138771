import React from 'react'
import './styles.css'
import { Box } from '@mui/material'
import asset from '../../assets/images/asset.png'
import profile from '../../assets/images/profile.png'
import fingerprint from '../../assets/images/fingerprint.png'

function Features() {

    const data = [
        {
            icon: asset,
            title:"Secure Asset Storage",
            desc:"Our industry-leading encryption and storage systems ensure that your assets are always safe and secure."
        },
        {
            icon: profile,
            title:"Strong Account Security",
            desc:"We adhere to the highest security standards and implement the strictest security practices to keep your account secure."
        },
        {
            icon: fingerprint,
            title:"Asset Transparency",
            desc:"Asset Transparency is a widely used method to prove custody of assets on the blockchain, confirming that KuCoin has the funds that cover all user assets on our books."
        },
    ]
   
    return (
        <Box className='featuresWrapper'>
            <h1>Your Secure and Reliable Cryptocurrency Exchange</h1>
            <Box className='featureContent'>
                {
                    data?.map((d) => {
                        return (
                            <Box className='featureBox'>
                                <img src={d.icon} alt={d.title} width={100} height={100} />
                                <h2 style={{color:"black"}}>{d.title}</h2>
                                <p style={{textAlign:"center" , opacity:0.5}}>{d.desc}</p>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default Features