import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Container } from '@mui/system';
import './styles.css'
import target from '../../assets/images/target.png'
import futures from '../../assets/images/futures.png'
import robot from '../../assets/images/robot.png'
import leveraged from '../../assets/images/leveraged.png'
import wallet from '../../assets/images/wallet.png'
import piggybank from '../../assets/images/piggybank.png'
import scales from '../../assets/images/scales.png'
import gem from '../../assets/images/gem.png'
function CryptoCurrency() {

    const data=[
        {
          title: "Spot Trading",
          description: "Trade crypto with our comprehensive set of powerful tools to maximize your profits.",
          iconName: target
        },
        {
          title: "Crypto Derivatives",
          description: "We are the best crypto exchange for trading crypto futures.",
          iconName: futures
        },
        {
          title: "Trading Bot",
          description: "Earn passive income round-the-clock without having to constantly monitor markets.",
          iconName: robot
        },
        {
          title: "Leveraged Tokens",
          description: "Amplify your investment returns by simply buying and selling leveraged tokens.",
          iconName: leveraged
        },
        {
          title: "Buy Crypto",
          description: "Purchase crypto quickly and easily on our popular and industry-leading platform.",
          iconName: wallet
        },
        {
          title: "Coin Earn",
          description: "Invest and earn steady income with the help of a professional asset manager.",
          iconName:piggybank
        },
        {
          title: "Margin Trading",
          description: "Borrow, trade, and repay. Leverage your assets with margin trading.",
          iconName: scales
        },
        {
          title: "GemSPACE",
          description: "Discover new coins with limitless potential, exclusively on KuCoin.",
          iconName: gem
        }
      ]
      

    return (
        <Box className="cryptoCurrencyWrapper" sx={{ mt: 4 }}>
            <h1 style={{color:"var(--black)" , textAlign:'center'}}>Explore Products & Services</h1>
            <Box className='cryptoCurrencyContent'>
                {
                    data?.map((d)=>{
                        return(
                            <Box className='cryptoCurrencyContentData'>
                                <h2 style={{color:"black"}}>{d.title}</h2>
                                <Box sx={{display:'flex' , alignItems:"center"}}>
                                    <h4 sx={{margin:0}}>{d.description}</h4>
                                    <img src={d.iconName} width={100} height={100}/>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default CryptoCurrency