import { Box, Button, FormControl, OutlinedInput, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { login } from '../../api'

function SignupFields({ setErrormessage, errorMessage }) {
    const cookie = new Cookies()
    let navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setErrormessage('')
            }, 5000); 
    
        return () => clearTimeout(timer);
      }, [errorMessage]);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            const func = login(username, password)
            if (username === "" && password === "") {
                navigate('/signin');
            } else {
                func.then(async (response) => {
                    if (response.success) {
                        cookie.set('token', response.token, { path: '/' })
                        cookie.set('firstname', response.data.firstName, { path: '/' })
                        cookie.set('username', response.data.username, { path: '/' })
                        cookie.set('id', response.data._id, { path: '/' })
                        cookie.set('wallet', response.data.wallet, { path: '/' })
                        cookie.set('balance', response.data.balance, { path: '/' })
                        cookie.set('currency', response.data.currency, { path: '/' })
                        navigate('/');
                    } else {
                        // console.log(response.message)
                        setErrormessage('user not found')
                    }
                })
            }

        }
    })


    return (
        <form onSubmit={formik.handleSubmit}>
            {
                errorMessage ?
                    <p className='signinErrorMessage'>{errorMessage}</p>
                    :
                    null
            }
            <Box className='inputContainer' style={{ marginBottom: 32 }}>
                <FormControl sx={{ width: '100%' }}>
                    <OutlinedInput
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        fullWidth
                        className='input'
                        id="username-required"
                        label="Username"
                        variant="outlined"
                        margin="none"
                        name="username"
                        placeholder='Username'
                    />
                </FormControl>

            </Box>
            <Box className='inputContainer' style={{ marginBottom: 32 }}>
                <FormControl sx={{ width: '100%' }}>
                    <OutlinedInput
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        type="password"
                        fullWidth
                        className='input'
                        id="password-required"
                        label="Password"
                        variant="outlined"
                        margin="none"
                        name="password"
                        placeholder='Password'
                    />
                </FormControl>
            </Box>
            <Box style={{ marginTop: 32 }}>
                <Button
                    type="submit"
                    variant="contained"
                    className='signinsubmitButton'
                >
                    Sign In
                </Button>
            </Box>
        </form>
    )
}

export default SignupFields