import { Box, Button } from '@mui/material'
import React from 'react'
import './style.css'
import box from '../../assets/images/box.png'
import { Link } from 'react-router-dom'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import EastIcon from '@mui/icons-material/East';
function MobileApp() {

    return (
        <Box className='mobileAppWrapper'>
            <Box>
                <img src={box} width={300} height={350}/>
            </Box>
            <Box sx={{width:"40%"}}>
                <h1>Try Our Crypto Exchange Now</h1>
                <h4 style={{color:"rgba(0,0,0,0.7)"}}>Start trading to earn exciting USDT rewards!</h4>
                <Link to="/signup" style={{textDecoration:"none", color:"var(--green)" , fontWeight:"bold" , display:'flex', alignItems:"center" , gap:"8px"}}>
                    View More <TrendingFlatIcon sx={{color:"var(--green)"}} />
                </Link>
                <Box className='mobileAppContent'>
                    <Box sx={{display:"flex" , alignItems:"center" , justifyContent:"space-between" , width:"100%"}}>
                        <h2 style={{color:"black"}}>Sign Up</h2>
                        <Button className='mobileAppButton'>
                            Sign Up <EastIcon sx={{color:'#ffffff' , marginLeft:"8px"}} />
                        </Button>
                    </Box>
                    <h4>Sign up and claim exciting USDT tokens, valuable coupons, and a Futures Trial Fund.</h4>
                </Box>
            </Box>
        </Box>
    )
}

export default MobileApp